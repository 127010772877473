<template>
  <div>
    <v-overlay :value="loading" :opacity="0.2" z-index="99">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <v-navigation-drawer v-model="menur" app height temporary right width="300">
      <v-list class="pa-1">
        <v-list-item>
          <avatar-upload />
          <v-list-item-content>
            <v-list-item-title class="ml-2" style="color: #292867">
              <b>{{ user.nome }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="color: #292867">{{
              user.email
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="userLoggedBy()" />
        <v-list-item v-if="userLoggedBy()">
          <v-list-item-content>
            <v-list-item-title style="color: #292867"
              >Logado por: {{ user.loggedBy.name }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="pt-0" dense>
        <v-divider v-if="!userLoggedBy() && hasPermissionToLoginWith()" />

        <v-list-item
          v-if="!userLoggedBy() && hasPermissionToLoginWith()"
          @click.stop="dialog = true"
          @click="menur = false"
        >
          <v-list-item-action>
            <v-icon>fas fa-user</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title style="color: #292867">
              <b>Logar Como</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="!userLoggedBy()" />

        <v-list-item to="/alterar-senha" v-if="!userLoggedBy()">
          <v-list-item-action>
            <v-icon>fas fa-key</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title style="color: #292867">
              <b>Alterar senha</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="isNative" />

        <v-list-item @click="openPushNotificationTokenDialog()" v-if="isNative">
          <v-list-item-action>
            <v-icon>fas fa-key</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title style="color: #292867">
              <b>Push Token</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="!userLoggedBy()" />

        <v-list-item
          @click="onClickNotificationPreference()"
          v-if="!userLoggedBy()"
        >
          <v-list-item-action>
            <v-icon>fas fa-bell</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title style="color: #292867">
              <b>Preferências de Notificações</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="!userLoggedBy()" />

        <v-list-item @click="logout()" v-if="!userLoggedBy()">
          <v-list-item-action>
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title style="color: #292867">
              <b>Sair</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="userLoggedBy()" />

        <v-list-item
          @click="returnLoginAsAnotherUserAction()"
          v-if="userLoggedBy()"
        >
          <v-list-item-action>
            <v-icon>fas fa-sign-out-alt fa-rotate-180</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title style="color: #292867">
              <b>Voltar</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-dialog v-model="dialog" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">Logar Como</v-card-title>

          <v-card-text>
            <v-autocomplete
              v-model="selectLoginAAU"
              :loading="loading"
              :items="usersLoginAAU"
              :search-input.sync="searchLoginAAU"
              hide-details
              label="Usuário"
              placeholder="Digite o nome ou login do usuário"
              outlined
              item-text="login"
              item-value="id"
              :disabled="loading || loggingAAU"
              clearable
              return-object
              :filter="filterUsersLoginAAU"
              autofocus
              :hide-no-data="!searchLoginAAUNoData || loading"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="$util.sanitize(data.item.name)"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="$util.sanitize(data.item.login)"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>

              <template v-slot:no-data> Usuário não encontrado! </template>
            </v-autocomplete>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="black--text"
              @click="dialog = false"
              :disabled="loggingAAU || loading"
            >
              Cancelar
            </v-btn>

            <v-btn
              :loading="loggingAAU"
              color="primary"
              @click="loginAsAnotherUserAction()"
              :disabled="!selectLoginAAU || loading"
            >
              Logar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPushToken" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">Push Notification Token</v-card-title>

          <v-card-text>
            {{ pushNotificationToken }}
            <input
              type="hidden"
              id="inputPushNotificationToken"
              :value="pushNotificationToken"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="black--text"
              @click="dialogPushToken = false"
            >
              Fechar
            </v-btn>

            <v-btn
              color="primary"
              @click="copyPushNotificationTokenToClipboard()"
            >
              Copiar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import {
  findAllUsersByAppCodeAndUser,
  loginAsAnotherUser,
  tratarMensagemErroLogin,
  getProfilePicture
} from "../../common/securityserver";
import AvatarUpload from "../generic-upload/AvatarUpload";
import { Capacitor } from "@capacitor/core";

export default {
  components: { AvatarUpload },
  methods: {
    ...mapMutations([
      "updateMenuState",
      "setProfilePicture",
      "showmsg",
      "setCurrentMenu",
      "setUserProfilePicture",
    ]),
    link(name) {
      this.$router.push({ name: name });
    },
    logout() {
      this.setCurrentMenu(this.user.menu[0]);
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    filterLoginAsAnotherUser(userNameOrLogin) {
      // cancel pending call
      clearTimeout(this._timerId);

      if (userNameOrLogin.length < 3) return;

      if (
        this.selectLoginAAU &&
        ((this.selectLoginAAU.login &&
          userNameOrLogin == this.selectLoginAAU.login) ||
          (this.selectLoginAAU.name &&
            userNameOrLogin == this.selectLoginAAU.name))
      )
        return;

      // delay new call
      this._timerId = setTimeout(() => {
        this.loading = true;
        findAllUsersByAppCodeAndUser(
          this.user.tokenSecurityService,
          userNameOrLogin
        )
          .then((response) => {
            this.configureUsers(response.data.content);
            this.selectLoginAAU = null;
          })
          .catch(() =>
            this.showmsg({ text: "Erro ao carregar usuários!", type: "error" })
          )
          .finally(() => (this.loading = false));
      }, 800);
    },
    configureUsers(data) {
      if (data.length) {
        this.usersLoginAAU = data.map((user) => ({
          id: user.id,
          name: user.name,
          login: user.login,
        }));
        this.searchLoginAAUNoData = false;
      } else {
        this.usersLoginAAU = [];
        this.searchLoginAAUNoData = true;
      }
    },
    filterUsersLoginAAU(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.login.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    userLoggedBy() {
      return this.user.loggedBy != null;
    },
    hasPermissionToLoginWith() {
      if (this.user.authorities) {
        let authority = this.user.authorities.filter(
          (a) => a.authority === "logar_como"
        );
        return authority.length > 0;
      } else return false;
    },
    loginAsAnotherUserAction() {
      this.loggingAAU = true;
      loginAsAnotherUser(this.user.tokenSecurityService, this.selectLoginAAU.id)
        .then((response) => {
          this.$store
            .dispatch("createSession", { 
              access_token: response.data.token,
              tinnyToken: response.data.tinnyToken,
              loginAs: true,
            }).then((response) => {
              this.setCurrentMenu(this.user.menu[0]);
              this.$router.push("/home");
              // window.location.reload();
              this.dialog = false;
              this.loggingAAU = false;
            })
            .catch((error) => {
              let friendlyMessage = "";
              friendlyMessage = tratarMensagemErroLogin(error);
              friendlyMessage = friendlyMessage === 'ACCESS_VALIDATION_ERROR_MESSAGE' ? 'Acesso Inválido' : friendlyMessage;
              this.showmsg({
                text: `Erro ao logar como ${this.selectLoginAAU.login}.\n${friendlyMessage}`,
                type: "error",
              });
              this.loggingAAU = false;
            });
        })
        .catch((error) => {
          let friendlyMessage = tratarMensagemErroLogin(error);
          friendlyMessage = friendlyMessage === 'ACCESS_VALIDATION_ERROR_MESSAGE' ? 'Acesso Inválido' : friendlyMessage;
          this.showmsg({
            text: `Erro ao logar como ${this.selectLoginAAU.login}.\n${friendlyMessage}`,
            type: "error",
          });
          this.loading = false;
          this.loggingAAU = false;
        });
    },
    returnLoginAsAnotherUserAction() {
      this.menur = false;
      this.loading = true;
      this.$store
        .dispatch("createSession", {
          access_token: this.$store.state.session.loggedBy.token,
          tinnyToken: this.$store.state.session.loggedBy.tinnyToken,
          returnLoginAs: true,
        })
        .then((response) => {
          this.setCurrentMenu(this.user.menu[0]);
          this.$router.push("/home");
          this.loading = false;
        })
        .catch(() => {
          this.showmsg({
            text: "Erro ao retornar do logar como",
            type: "error",
          });
          this.loading = false;
        });
    },
    openPushNotificationTokenDialog() {
      this.dialogPushToken = true;
    },
    copyPushNotificationTokenToClipboard() {
      let testingCodeToCopy = document.querySelector(
        "#inputPushNotificationToken"
      );
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        alert("Push Token foi copiado para o clipboard " + msg);
      } catch (err) {
        alert("Oops, não foi possível copiar!");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    onClickNotificationPreference() {
      this.menur = false;
      this.$router.push({ name: "notification-preferences" });
    },
  },
  created() {
    getProfilePicture(this.$store.getters.user.tokenSecurityService)
    .then(response => {
      this.setUserProfilePicture(response.imgContent)
    });
  },
  computed: {
    ...mapState(["menu"]),
    ...mapGetters(["user"]),
    menur: {
      get: function () {
        return this.menu.right;
      },
      set: function (value) {
        this.updateMenuState({ right: value });
      },
    },
    pushNotificationToken() {
      return localStorage.getItem("PUSH_NOTIFICATION_TOKEN");
    },
    isNative() {
      return Capacitor.isNative;
    },
  },
  data: () => ({
    fileTypes: [
      "image/apng",
      "image/bmp",
      "image/gif",
      "image/jpeg",
      "image/pjpeg",
      "image/png",
      "image/svg+xml",
      "image/tiff",
      "image/webp",
      "image/x-icon",
    ],
    dialog: false,
    loading: false,
    usersLoginAAU: [],
    searchLoginAAU: null,
    selectLoginAAU: null,
    loggingAAU: false,
    searchLoginAAUNoData: false,
    dialogPushToken: false,
  }),
  watch: {
    searchLoginAAU(val) {
      val && this.filterLoginAsAnotherUser(val);
    },
  },
};
</script>
