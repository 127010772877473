import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.$route.meta.hideMenu)?_c(VAppBar,{staticClass:"white--text",attrs:{"flat":"","color":_vm.userLoggedBy() ? '#C9AAD1' : 'primary',"app":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenul.apply(null, arguments)}}},[_c(VIcon,{staticClass:"white--text"},[_vm._v("fas fa-bars")])],1),_c(VToolbarTitle,{staticClass:"text-truncate pl-0 ml-4"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VSpacer),(_vm.userLoggedBy() && _vm.$resize && _vm.$mq.above(_vm.window.mobileSize))?_c('span',[_vm._v(" "+_vm._s(_vm.user.loggedBy.name)+" "),_c('b',[_vm._v(" Como: ")]),_vm._v(_vm._s(_vm.user.nome ? _vm.user.nome : _vm.user.name ? _vm.user.name : '')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.user.nome ? _vm.user.nome : _vm.user.name ? _vm.user.name : '')+" ")]),_c('notifications',{key:_vm.user.email}),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenur.apply(null, arguments)}}},[_c(VAvatar,{attrs:{"size":"40"}},[_c('img',{attrs:{"src":_vm.user.profilePicture}})])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }